import { action, observable } from 'mobx';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AlertStore } from '@uvgo-shared/alert';
import { Logger } from '@wings-shared/security';
import { IAPIGridRequest, IAPIPageResponse, Utilities, tapWithAction } from '@wings-shared/core';
import { BulletinModel, UAOfficesModel } from '../Models';
import { SettingsBaseStore, apiUrls } from '../../../Stores';
import { IAPIBulletin } from '../Interfaces';
import { NO_SQL_COLLECTIONS } from '../../../Enums';
import { baseApiPath } from '../../../API';
import { HttpClient } from '../../../Tools';

export class BulletinStore extends SettingsBaseStore {
  @observable uaOffices: UAOfficesModel[] = [];

  constructor(baseUrl) {
    super(baseUrl);
  }

  @action
  public getBulletins(pageRequest?: IAPIGridRequest): Observable<IAPIPageResponse<BulletinModel>> {
    const params: string = Utilities.buildParamString({
      pageNumber: 1,
      pageSize: 0,
      ...pageRequest,
    });

    return this.http.get<IAPIPageResponse<IAPIBulletin>>(`${apiUrls.bulletin}?${params}`).pipe(
      Logger.observableCatchError,
      map(response => ({ ...response, results: BulletinModel.deserializeList(response.results) }))
    );
  }

  @action
  public getBulletinsNoSql(
    pageRequest: IAPIGridRequest,
    collectionName: NO_SQL_COLLECTIONS
  ): Observable<IAPIPageResponse<IAPIBulletin>> {
    const params: string = Utilities.buildParamString({
      pageNumber: 1,
      pageSize: 0,
      collectionName,
      ...pageRequest,
    });

    return new HttpClient({ baseURL: baseApiPath.noSqlData })
      .get<IAPIPageResponse<IAPIBulletin>>(`${apiUrls.referenceData}?${params}`)
      .pipe(
        Logger.observableCatchError,
        map(response => ({ ...response, results: BulletinModel.deserializeList(response.results) }))
      );
  }

  /* istanbul ignore next */
  @action
  public getBulletinById(bulletinId: number): Observable<BulletinModel> {
    return this.http.get<IAPIBulletin>(`${apiUrls.bulletin}/${bulletinId}`).pipe(
      Logger.observableCatchError,
      map(response => BulletinModel.deserialize(response))
    );
  }

  /* istanbul ignore next */
  public upsertBulletin(request: IAPIBulletin): Observable<BulletinModel> {
    const isNewRequest: boolean = request.id === 0;
    const upsertRequest: Observable<IAPIBulletin> = isNewRequest
      ? this.http.post<IAPIBulletin>(apiUrls.bulletin, request)
      : this.http.put<IAPIBulletin>(`${apiUrls.bulletin}/${request.id}`, request);

    return upsertRequest.pipe(
      Logger.observableCatchError,
      map(response => BulletinModel.deserialize(response)),
      tap(() => AlertStore.info(`Bulletin ${isNewRequest ? 'created' : 'updated'} successfully!`))
    );
  }

  /* istanbul ignore next */
  public loadUAOffices(forceRefresh?: boolean): Observable<UAOfficesModel[]> {
    const params: string = Utilities.buildParamString({
      pageNumber: 1,
      pageSize: 0,
      collectionName: NO_SQL_COLLECTIONS.UA_OFFICE,
      sortCollection: JSON.stringify([{ propertyName: 'uaOfficeId', isAscending: false }]),
    });
    return this.getResult(
      `${apiUrls.referenceData}?${params}`,
      this.uaOffices,
      forceRefresh,
      UAOfficesModel.deserializeList,
      {
        baseUrl: baseApiPath.noSqlData,
      }
    ).pipe(tapWithAction(uaOffices => (this.uaOffices = uaOffices)));
  }
}
